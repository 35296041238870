const colorList = [
  { title: 'Grey', color: '#807c5e', text: 'white' },
  { title: 'Grey', color: '#616438', text: 'white' },
  { title: 'Black', color: '#555024', text: 'white' },
  { title: 'Black', color: '#3d402c', text: 'white' },
  { title: 'Gold', color: '#eedb8e', text: 'black' },
  { title: 'Gold', color: '#ecd157', text: 'black' },
  { title: 'Gold', color: '#e7be24', text: 'black' },
  { title: 'Gold', color: '#d3b933', text: 'black' },
  { title: 'Gold', color: '#d0bb5a', text: 'black' },
  { title: 'Gold', color: '#bea838', text: 'black' },
  { title: 'Gold', color: '#b59811', text: 'black' },
  { title: 'Gold', color: '#847214', text: 'white' },
  { title: 'Emerald', color: '#2ecc71', text: 'black' },
  { title: 'Peter River', color: '#3498db', text: 'white' },
  { title: 'Amethyst', color: '#9b59b6', text: 'white' },
  { title: 'Wet Asphalt', color: '#34495e', text: 'white' },
  { title: 'Green Sea', color: '#16a085', text: 'black' },
  { title: 'Nephritis', color: '#27ae60', text: 'black' },
  { title: 'Belize Hole', color: '#2980b9', text: 'white' },
  { title: 'Wisteria', color: '#8e44ad', text: 'white' },
  { title: 'Midnight Blue', color: '#2c3e50', text: 'white' },
  { title: 'Sun Flower', color: '#f1c40f', text: 'black' },
  { title: 'Carrot', color: '#e67e22', text: 'black' },
  { title: 'Alizarin', color: '#e74c3c', text: 'black' },
  { title: 'Clouds', color: '#ecf0f1', text: 'black' },
  { title: 'Concrete', color: '#95a5a6', text: 'black' },
  { title: 'Orange', color: '#f39c12', text: 'black' },
  { title: 'Pumpkin', color: '#d35400', text: 'white' },
  { title: 'Pomegranate', color: '#c0392b', text: 'white' },
  { title: 'Silver', color: '#bdc3c7', text: 'black' },
  { title: 'Asbestos', color: '#7f8c8d', text: 'white' },
  { title: 'Umi Theme', color: '#1da57a', text: 'white' },
  { title: 'Primary', color: '#1890ff', text: 'black' },
  { title: 'Link', color: '#1890ff', text: 'black' },
  { title: 'Success', color: '#52c41a', text: 'black' },
  { title: 'Warning', color: '#faad14', text: 'black' },
  { title: 'Error', color: '#f5222d', text: 'black' },
  { title: 'Border', color: '#d9d9d9', text: 'white' },
  { title: 'NTG Dark', color: '#336', text: 'white' },
  { title: 'NTG Dark', color: '#366', text: 'white' },
  { title: 'NTG Dark', color: '#369', text: 'white' },
  { title: 'NTG Blue', color: '#669', text: 'white' },
  { title: 'NTG Blue', color: '#699', text: 'white' },
  { title: 'NTG Blue', color: '#69c', text: 'white' },
  { title: 'NTG Light', color: '#99c', text: 'black' },
  { title: 'NTG Light', color: '#9cc', text: 'black' },
  { title: 'NTG Light', color: '#9cf', text: 'black' },
  { title: 'NTG Neutral', color: '#996', text: 'white' },
  { title: 'NTG Neutral', color: '#969', text: 'white' },
  { title: 'NTG Neutral', color: '#966', text: 'white' },
  { title: 'NTG Neutral', color: '#663', text: 'white' },
  { title: 'NTG Neutral', color: '#636', text: 'white' },
  { title: 'NTG Neutral', color: '#633', text: 'white' },
  { title: 'NTG Neutral', color: '#cc9', text: 'black' },
  { title: 'NTG Neutral', color: '#c9c', text: 'black' },
  { title: 'Akaroa', color: '#dacbb4', text: 'black' },
  { title: 'Marlin', color: '#3e2818', text: 'white' },
  { title: 'Dallas', color: '#774e2a', text: 'white' },
  { title: 'Shadow', color: '#906e49', text: 'white' },
  { title: 'Muted 1', color: '#c8b7a6', text: 'black' },
  { title: 'Muted 2', color: '#7d94b5', text: 'white' },
  { title: 'Muted 3', color: '#c29591', text: 'black' },
  { title: 'Muted 4', color: '#703f37', text: 'white' },
  { title: 'Muted 5', color: '#b6c199', text: 'black' },
  { title: 'Creme 1', color: '#f4d8ae', text: 'black' },
  { title: 'Creme 2', color: '#dc7027', text: 'black' },
  { title: 'Creme 3', color: '#b6c4be', text: 'black' },
  { title: 'Creme 4', color: '#ea8a81', text: 'black' },
  { title: 'Creme 5', color: '#301008', text: 'white' }
];

export default colorList;